import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContext } from '../types/pageContext';
import ProductGrid from '../components/ProductGrid';
import {
  IContentProduct,
  ITopicCategory,
  ITopicProductFamily,
  ITopicProductLine,
} from '../types/contentfulContentTypes';

export const query = graphql`
  query ProductListsPageTemplate(
    $id: String!
    $productLineId: String
    $productFamilyId: String
  ) {
    contentfulPageProductsList(id: { eq: $id }) {
      id
      contentful_id
      pageTitle
      pageTitle
      productLine {
        id
        title
        slug
        categories {
          name
          slug
        }
      }
      productFamily {
        id
        title
        slug
      }
      category {
        id
        name
        slug
        subcategories {
          title
          slug
        }
      }
      subcategory {
        id
        title
        slug
      }
    }
    allContentfulContentProduct(
      filter: {
        isDiscontinued: { eq: false }
        productLine: { elemMatch: { id: { eq: $productLineId } } }
        productFamily: { elemMatch: { id: { eq: $productFamilyId } } }
      }
    ) {
      edges {
        node {
          id
          contentful_id
          internal {
            type
          }
          slug
          name
          model
          featureImage {
            ...AssetFragment
            gatsbyImageData(placeholder: BLURRED)
          }
          availableFrom
          isAccessory
          productLine {
            id
            title
            slug
          }
          productFamily {
            id
            title
            slug
          }
          category {
            id
            name
            slug
          }
          subcategory {
            id
            title
            slug
          }
          topic__product_filter {
            name
            slug
            groupName
          }
        }
      }
    }

    contentfulGlobalOptions {
      ...GlobalFragment
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;
const ProductListPageTemplate: React.FC<
  PageProps<Queries.ProductListsPageTemplateQuery, PageContext>
> = (props) => {
  const { data } = props;
  const { allContentfulContentProduct, contentfulPageProductsList } = data;

  const { productLine, productFamily, category, subcategory } =
    contentfulPageProductsList;
  const products = allContentfulContentProduct?.edges.map(({ node }) => node);

  return (
    <ProductGrid
      products={products as IContentProduct[]}
      productLine={productLine as ITopicProductLine}
      productFamily={productFamily as ITopicProductFamily}
      selectedCategory={category as ITopicCategory}
      selectedSubcategory={subcategory}
    />
  );
};
export default ProductListPageTemplate;
